/**
 *  Pages / Certificates
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Certificates page
 *
 */

/** Dependencies */
import React from "react";
import { Helmet } from "react-helmet";

/** Local Dependencies */
import { Contact } from "../sections/Contact";
import { Head } from "../components/Head";
import { Typography } from "../components/Typography";

/** Images */
import certificate1Url from "../images/certificates/certificate-1.png";
import certificate2Url from "../images/certificates/certificate-2.png";
import certificate3Url from "../images/certificates/certificate-3.png";
import declarations1Url from "../images/certificates/declaration-1.png";
import declarations2Url from "../images/certificates/declaration-2.png";
import declarations3Url from "../images/certificates/declaration-3.png";
import declarations4Url from "../images/certificates/declaration-4.png";
import declarations5Url from "../images/certificates/declaration-5.png";
import backgroundUrl from "../images/plant-3.jpg";

/** Styles */
import * as styles from "./certificates.module.scss";

const CertificatesPage: React.FC = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Примабилд ООД - Сертификати</title>
      <link rel="canonical" href="https://www.primabuild.com/certificates" />
    </Helmet>
    <Head title="Сертификати" backgroundSrc={backgroundUrl}>
      <Typography
        variant="headlineMedium"
        component="h2"
        className={styles.headText}
      >
        Нашият бетон съответства на всички изискуеми сиртификати
      </Typography>
    </Head>
    <div className={styles.certificates}>
      <Typography variant="bodyMedium">
        Примабилд ООД разполага със сертификати за съответствие на
        произвежданите строителни продукти, които съответстват на нациoлналните
        изисквания на Република България според{" "}
        <strong>
          БДС EN 206: 2013+A1: 2016 , БДС EN 206: 2013 + A1: 2016 / NA: 2017.
        </strong>
      </Typography>
      <Typography variant="bodySmall">
        Валидността на сертификатите може да бъде проверена на www.dedal-bg.net.
      </Typography>
      <div className={styles.certificatesImages}>
        <div className={styles.certificatesImagesPortrait}>
          <img src={certificate1Url} alt="сертификат за бетон" />
        </div>
        <div className={styles.certificatesImagesLandscape}>
          <img src={certificate2Url} alt="сертификат за бетон" />
          <img src={certificate3Url} alt="сертификат за бетон" />
        </div>
      </div>
    </div>
    <div className={styles.declarations}>
      <Typography variant="headlineLarge" className={styles.declarationsTitle}>
        Декларации за експлоатационни показатели на суровини
      </Typography>
      <Typography variant="bodyMedium">
        Разполагаме със сертификати за суровините, изплозвани за производството
        на бетон и бетонови смеси. Сертификатите принадлежат на доставчиците на
        суровините.
      </Typography>
      <div className={styles.declarationsImages}>
        <div className={styles.declarationsRow}>
          <img
            src={declarations1Url}
            alt="декларация за съответствие"
            className={styles.declarationsImage}
          />
          <img
            src={declarations2Url}
            alt="декларация за съответствие"
            className={styles.declarationsImage}
          />
          <img
            src={declarations3Url}
            alt="декларация за съответствие"
            className={styles.declarationsImage}
          />
        </div>
        <div className={styles.declarationsRow}>
          <img
            src={declarations4Url}
            alt="декларация за съответствие"
            className={styles.declarationsImage}
          />
          <img
            src={declarations5Url}
            alt="декларация за съответствие"
            className={styles.declarationsImage}
          />
        </div>
      </div>
    </div>
    <Contact />
  </>
);

export default CertificatesPage;
