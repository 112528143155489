// extracted by mini-css-extract-plugin
export var certificates = "certificates-module--certificates--EY8at";
export var certificatesImages = "certificates-module--certificatesImages--Ze8yC";
export var certificatesImagesLandscape = "certificates-module--certificatesImagesLandscape--mwPfd";
export var certificatesImagesPortrait = "certificates-module--certificatesImagesPortrait--C3wz2";
export var declarations = "certificates-module--declarations--WZcsk";
export var declarationsImage = "certificates-module--declarationsImage--K7FfH";
export var declarationsImages = "certificates-module--declarationsImages--fSSsF";
export var declarationsRow = "certificates-module--declarationsRow--e6Ktf";
export var declarationsTitle = "certificates-module--declarationsTitle--3TVTj";
export var headText = "certificates-module--headText--x8Fp3";